<template>
  <div
    style="margin-top: 0px;"
    @click="removeLists"
  >
    <div>
      <div style="margin-right: 10px;display: flex;align-items: flex-end;">
        <b-button
          v-b-modal.create-order-location
          class="buttonSubmit"
          style="height: 40px;width:150px"
          @click="createOrderLocation"
        >
          {{ $t("CreateOrderLocation") }}
        </b-button>
        <p
          v-if="notificationNumber != null"
          class="bulletPoint"
        >
          1
        </p>
      </div>
      <div class="filtering">

        <div class="search-toggle">
          <p>{{ $t('SearchBy') }}</p>
          <div>
            <p
              :class="{ active2: !toggleSarch }"
              @click="showCustomerName()"
            >
              {{ $t('CustomerName') }}
            </p>
            <p
              :class="{ active2: toggleSarch }"
              @click="showArticleName()"
            >
              {{ $t('ArticleNumber') }}
            </p>
          </div>
        </div>
        <div v-if="show == true">
          <div>
            <div class="black">
              <b-icon-search
                v-if="searchCN == ''"
                class="search1"
                style="position: absolute; left: 5px; top: 12px"
              />
              <b-icon-x-circle
                v-else-if="searchCN != '' && !noSearch"
                class="search1"
                style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                @click="searchCN = ''"
              />
              <input
                v-model="searchCN"
                type="text"
                autocomplete="off"
                name="search-box"
                :placeholder="$t('CustomerName')"
                :style="getFirstNames.length > 0 && noSearchTwo ? 'border-radius: 8px 8px 0px 0px' : ''"
                @keyup="inputChanged"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
              >
            </div>
            <div
              v-if="getFirstNames.length > 0 && noSearchTwo"
              ref="scrollContainer"
              class="dropdrop"
              style="
              display: inline-block;
              overflow: auto;
              position: absolute;
              background: white;
              width: 229px;
              box-shadow: none;
            "
              :style="getFirstNames.length > 7 ? 'height: 210px' : 'height: auto'"
            >
              <div
                v-for="(result, index) in getFirstNames"
                ref="options2"
                :key="index"
                :class="{ 'is-active': index === arrowCounter }"
                style="cursor: pointer"
                class="search-item"
                @click="searchByCn(result ); noSearchTwo = false"
              >
                <p style="margin: 0">
                  {{ result.nameToDisplay }}
                </p>
              </div>
              <div
                v-if="getFirstNames.length == 0"
                class="search-item"
              >
                <p>{{ $t('NoResult') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="show == false">
          <div class="black">
            <b-icon-search
              v-if="searchAN == ''"
              class="search2"
              style="position: absolute; left: 5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchAN != '' && !noSearchTwo"
              class="search1"
              style="position: absolute; right: 20px; top: 12px; cursor: pointer"
              @click="searchAN = ''"
            />
            <input
              v-model="searchAN"
              type="text"
              name="search-box"
              :style="getArticleNumberLike.length > 0 && noSearchTwo ? 'border-radius: 8px 8px 0px 0px' : ''"
              :placeholder="$t('ArticleNumber')"
              @keyup="inputChanged2"
              @keydown.down="onArrowDown2"
              @keydown.up="onArrowUp2"
            >
          </div>
          <div
            v-if="getArticleNumberLike.length > 0 && noSearchTwo"
            ref="scrollContainer2"
            class="dropdrop"
            style="
              display: inline-block;
              overflow: auto;
              position: absolute;
              background: white;
              width: 229px;
              box-shadow: none;
            "
            :style="getArticleNumberLike.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getArticleNumberLike"
              :key="index"
              ref="options2"
              style="cursor: pointer"
              :class="{ 'is-active': index === arrowCounter2 }"
              class="search-item"
              @click="searchByAn(result); noSearchTwo = false"
            >
              <p style="margin: 0">
                {{ result.locationName }}
              </p>
            </div>
            <!-- <div
                v-if="getFilteredSuppliersByCN.length == 0"
                class="search-item"
              >
                <p>Sorry. No Results.</p>
              </div> -->
          </div>
        </div>
      </div>
      <div
        style="width: 100%;padding-top: 16px; margin-top: 0px;"
        class="scroll"
      >
        <table
          ref="exportable_table"
          class="team_table"
        >
          <thead>
            <tr style="border-bottom: 1px solid #dcdfe6; color: #606266; text-align: left">
              <th>
                {{ $t("No") }}
              </th><th>
                {{ $t('OrderNumber') }}
              </th>
              <th>
                {{ $t('ArticleNumber') }}
              </th>
              <th>
                {{ $t('Location Name') }}
              </th>
              <!-- <th style="padding: 10px; border-top-left-radius: 10px; color:#262E6C !important;font-size: 15px;">
                {{ $t('locationType') }}
              </th> -->
              <th>
                {{ $t('Notes') }}
              </th>
              <th>
                {{ $t('Clients') }}
              </th>
              <th>
                {{ $t('State') }}
              </th>
              <th>
                {{ $t('Actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in getOrderItemLocation"
              :key="index"
              style="border-bottom: 1px solid #EAECF0; color:#475467"
            >
              <!-- @click="matProps(supplier)" -->
              <td>
                {{ getTotalItemsForOrders - (pageNumber - 1) * pageSize - index }}.
              </td>
              <td>
                {{ item.orderNumber }}
              </td>
              <td>
                {{ item.articleNumber }}
              </td>
              <td>
                {{ item.locationName }}
              </td>
              <!-- <td style="padding: 9px; text-align: left; font-weight: 400; color: black;">
                {{ item.locationType }}
              </td> -->
              <td>
                <button
                  v-b-modal.location-notes
                  class="editButton"
                  style="width:50%"
                  @click="notesReview(item)"
                >
                  {{ $t('Notes') }}
                </button>
              </td>
              <td>
                {{ item.clientMainData?.fullName }}
              </td>
              <td>
                <button
                  v-if="item.currentState == 'ReadyToDeliver'"
                  v-b-modal.modal-deliver-modal
                  class="editButton"
                  @click="changeState(item.orderItemId, item.locationId, item.orderNumber)"
                >
                  {{ $t("Deliver") }}
                </button>
                <p
                  v-else
                  class="p-0 m-0 ml-50"
                >
                  {{ item.currentState == 'Delivered' ? 'Delivered Successfully' : $t(item.currentState) }}
                </p>
              </td>
              <td
                v-b-toggle.edit-order-item-location-toggle
                @click="editItem(item.orderItemId, item.locationId, item.notes, item.locationName)"
              >

                <p
                  class="p-0 m-0 ml-50"
                >
                  <b-icon-pencil />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <b-pagination
          v-if="getTotalItemsForOrders > 15"
          v-model="pageNumber"
          :total-rows="getTotalItemsForOrders"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          prev-class="prev-itemi"
          next-class="next-itemi"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>
    <EditOrderItemLoction
      :edit-order-location="editOrderItemLocation"
      @edit="editOrderItem"
    />
    <EditOrderItemLoctionToggle
      :edit-order-location="editOrderItemLocation"
      @onCancelToggle="onCancelToggle"
      @edit="editOrderItem"
    />
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
    <Deliver :sure="propObject" />
    <CreateOrderLocation
      ref="childComponentRef"
      @createOrderLoc="createOrderInLocation"
    />
    <LocationNotes
      :notes-clients="notesClients"
    />

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import AddSupplierModal from '@/modals/AddSupplierModal.vue';
// import EditRowModal from '@/modals/EditRowModal.vue';
// import { countryCallingCodes } from '../countryCodes'
import EditOrderItemLoctionToggle from '@/components/location/modals/EditOrderItemLocationToggle.vue'
import CreateOrderLocation from '@/components/productsInStock/modals/CreateOrderLocation.vue'
import EditOrderItemLoction from './modals/EditOrderItemLocation.vue'
import Deliver from './modals/DeliverModal.vue'
import LocationNotes from './modals/LocationNotes.vue'


/*  eslint-env es6  */
export default {
  components: {
    EditOrderItemLoction,
    Deliver,
    EditOrderItemLoctionToggle,
    CreateOrderLocation,
    LocationNotes,
  },
  // props: {
  //   materialId: {
  //     type: String,
  //     default: '',
  //   },
  // },
  data() {
    return {
      notificationNumber: null,
      isSidebarOpen: false,
      // countryCallingCode: countryCallingCodes,
      toggleSarch: false,
      show: true,
      searchCN: '',
      searchAN: '',
      noSearch: false,
      noSearchTwo: false,
      locationtype: null,
      propObject: {},
      locationByType: null,
      editOrderItemLocation: {
        orderItemId: null,
        locationId: null,
        notes: null,
        locationName: null,
      },
      pageNumber: 1,
      pageSize: 15,
      editLocationn: {
        // locationId: '',
        locationName: '',
        locationType: '',
        notes: '',
      },
      notesClients: null,
      deactivateItemId: {},
      totalItems: '',
      rows: [],
      arrowCounter: 0,
      arrowCounter2: 0,
      enterPressed: false,
    };
  },
  computed: {
    ...mapGetters([
      'getSuppliers',
      'getCurrentPageForSuppliers',
      'getTotalItemsForSuppliers',
      'getLoggedInUser',
      'getFilteredSuppliersBySN',
      'getFilteredSuppliersByCN',
      'getLocation',
      'getLocationTypes',
      'getLocationsByType',
      'getLocationByPagination',
      'getLocationByPaginationTotalItems',
      'getFilterLocation',
      'getOrderItemLocation',
      'getTotalItemsForOrders',
      'getFirstNames',
      'getArticleNumberLike',
    ]),
  },
  watch: {
    pageNumber(value) {
      this.orderItem_location_Pagination({
        orderItemId: null,
        articleNumber: null,
        clientId: null,
        pageNumber: value,
        pageSize: this.pageSize,
      })
    },
    searchCN(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
        // this.fshijCN();
          this.orderItem_location_Pagination({
            orderItemId: null,
            articleNumber: null,
            clientId: null,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return
        } else {
          this.firstNames(value);
          this.noSearchTwo = true
        }
      }, 500)
    },
    searchAN(value) {
      if (value == '') {
        // this.fshijCN();
        this.orderItem_location_Pagination({
          orderItemId: null,
          articleNumber: null,
          clientId: null,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        })
      } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
        return
      } else {
        this.orderItemLocation_articleNumberLike(value);
        this.noSearchTwo = true
      }
    },
    locationtype(value) {
      console.log(value)
      // this.locations_by_type(value)
      this.locationPagination({
        locationId: null,
        locationType: value,
        pageSize: this.pageSize,
        pageNumber: this.page,
      })
    },
  },
  mounted() {
    this.loadLocationTypes()

    this.fetch()
    // console.log('hello', this.getLocation)
  },
  methods: {

    ...mapActions([
      'loadSuppliers',
      'addSupplier',
      'editSupplier',
      'getSuppliersCompanyNameLikeORContactNameLike',
      'deactivateSupplierById',
      'changeLoadingtoTrue',
      'filterSuppliersBySN',
      'resetFilteredSupplierBySN',
      'filterSuppliersByCN',
      'resetFilteredSupplierByCN',
      'addLocation',
      'editLocation',
      'locationi',
      'loadLocationTypes',
      'locations_by_type',
      'locationPagination',
      'filterLocationBy',
      'orderItem_location_Pagination',
      'firstNames',
      'orderItemLocation_articleNumberLike',
      'change_orderItem_location',
      'updateOrderStatus',
      'createOrderInStoreLocation',
      'loadSizeTypes',
      'locations_by_type',
    ]),
    createOrderInLocation(obj) {
      this.notificationNumber = obj.orderNumber
      this.createOrderInStoreLocation({
        object: obj,
        successCallback: () => {
          this.$refs.childComponentRef.resetCustomerData();
          this.orderItem_location_Pagination({
            orderItemId: null,
            articleNumber: null,
            clientId: null,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          })
          this.notificationNumber = null
        },
      })
    },
    createOrderLocation() {
      this.$refs.childComponentRef.resetPhotos();
      this.loadSizeTypes()
      this.locations_by_type('WarehouseForClient')
    },
    notesReview(item) {
      this.notesClients = item.notes;
    },
    addLocationn(value) {
      this.addLocation({
        object: value,
        successCallback: () => {
          this.loadLocationTypes()
        },
      });
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    editItem(order, location, notes, locationName) {
      this.locations_by_type('WarehouseForClient')
      this.isSidebarOpen = true
      this.editOrderItemLocation.orderItemId = order;
      this.editOrderItemLocation.locationId = location;
      this.editOrderItemLocation.notes = notes;
      this.editOrderItemLocation.locationName = locationName;
    },
    editOrderItem(value) {
      // console.log(value)
      this.change_orderItem_location({
        object: value,
        successCallback: () => {
          this.orderItem_location_Pagination({
            orderItemId: null,
            articleNumber: null,
            clientId: null,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          })
        },
      })
    },
    async fetch() {
      try {
        //         // this.changeLoadingtoTrue(false)


        // await this.loadSuppliers({
        //   pageNumber: this.page,
        //   pageSize: this.pageSize,
        //   supplierName: this.searchCN,
        //   contactName: this.searchAN,
        // })
        await this.orderItem_location_Pagination({
          orderItemId: null,
          articleNumber: null,
          clientId: null,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFirstNames.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    changeState(order, location, orderNumber) {
      const obj = {
        orderNumber,
        orderItemId: order,
        newState: 'Delivered',
        locationId: location,

      }

      this.propObject = obj
      // this.updateOrderStatus({
      //   object: obj,
      //   successCallback: () => {
      //     this.orderItem_location_Pagination({
      //       orderItemId: null,
      //       articleNumber: null,
      //       clientId: null,
      //       pageNumber: this.pageNumber,
      //       pageSize: this.pageSize,
      //     })
      //   },
      // })
      // this.change_orderItem_location({
      //   object: obj
      // })
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },


    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByCn(this.getFirstNames[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    onArrowDown2(ev) {
      ev.preventDefault()
      if (this.arrowCounter2 < this.getArticleNumberLike.length - 1) {
        this.arrowCounter2 += 1;
        this.fixScrolling2();
      }
    },

    onArrowUp2(ev) {
      ev.preventDefault()
      if (this.arrowCounter2 > 0) {
        this.arrowCounter2 -= 1;
        this.fixScrolling2()
      }
    },


    inputChanged2(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByAn(this.getArticleNumberLike[this.arrowCounter2])
        this.removeLists()
        this.arrowCounter2 = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling2() {
      const liH = this.$refs.options2[this.arrowCounter2].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer2.scrollTop = liH * this.arrowCounter2;
    },
    showCustomerName() {
      this.toggleSarch = false;
      this.searchCN = '';
      this.show = true
      this.orderItem_location_Pagination({
        orderItemId: null,
        articleNumber: null,
        clientId: null,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      })
    },
    showArticleName() {
      this.toggleSarch = true;
      this.searchAN = '';
      this.show = false;
      this.orderItem_location_Pagination({
        orderItemId: null,
        articleNumber: null,
        clientId: null,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      })
    },
    async searchByAn(value) {
      this.searchAN = value.locationName
      this.noSearch = false
      await this.orderItem_location_Pagination({
        orderItemId: null,
        articleNumber: this.searchAN,
        clientId: null,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      })
    },
    async searchByCn(value) {
      this.searchCN = value.nameToDisplay
      this.noSearchTwo = false
      await this.orderItem_location_Pagination({
        orderItemId: null,
        articleNumber: null,
        clientId: value.clientId,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      })
    },
    removeLists() {
      this.noSearch = false;
      this.noSearchTwo = false;
      this.resetFilteredSupplierBySN();
      this.resetFilteredSupplierByCN();
    },
  },
};
</script>

<style scoped lang="scss">
.bulletPoint {
  background-color: aliceblue;
  color: black;
  border-radius: 50%;
  position: relative;
  left: -21px;
  top: -15px;
  width: 30px;
  display: flex;
  justify-content: center;
}
.team_table td {
  padding: 11px 9px !important;
}
.active2 {
  font-weight: bold;
  color: $base-color;
}
.active2{
  color: $base-color;
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .scroll {
    overflow-x: scroll;
  }

}

.dropdrop::-webkit-scrollbar {
  display: none;

}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

.is-active {
  background-color: #dedede;
}
</style>
